export const ComplexGenericsItems = [
  
  
  {
    srno: 1,
    productID: "2001",
    rLDProduct_Dev: "XIGDUO XR",
    productName: "Metformin Extended Release + Dapagliflozin Tablets (2.5 +1000, 5 + 500, 5 +1000, 10 + 500, 10 + 1000 mg)",
    productDevStatus: "",
    pilotBE: "Pending",
    scaleUp: "Pending",
    pivotalBE: "Pending",
    technologyTransfer: "Pending",
    remark: "Development Ongoing",
    avaliableIn: [
      {
        key: "USA",
        value: true,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },
  
  {
    srno: 2,
    productID: "2002",
    rLDProduct_Dev: "Duexis",
    productName: "Ibuprofen and Famotidine Tablets (800+ 26.6 mg)",
    productDevStatus: "Done",
    pilotBE: "Done",
    scaleUp: "Done",
    pivotalBE: "Done",
    technologyTransfer: "",
    remark: "Under Filing",
    avaliableIn: [
      {
        key: "USA",
        value: false,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },
  {
    srno: 3,
    productID: "2003",
    rLDProduct_Dev: "Adalat GITS",
    productName: "Nefidipine Extended Release Tablets (30mg)",
    productDevStatus: "Done",
    pilotBE: "Done",
    scaleUp: "",
    pivotalBE: "Pending",
    technologyTransfer: "Pending",
    remark: "Ready for TT",
    avaliableIn: [
      {
        key: "USA",
        value: true,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: false,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },
  {
    srno: 4,
    productID: "2004",
    rLDProduct_Dev: "Inderal LA (USA)",
    productName: "Propranolol Hydrochloride ER Capsules (60 mg ,80 mg,120 mg and 160 mg",
    productDevStatus: "Done",
    pilotBE: "Done",
    scaleUp: "Pending",
    pivotalBE: "Pending",
    technologyTransfer: "Pending",
    remark: "6M Stability Done. Ready for Scale up",
    avaliableIn: [
      {
        key: "USA",
        value: true,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },
  {
    srno: 5,
    productID: "2005",
    rLDProduct_Dev: "Slenyto",
    productName: "Melatonin Prolonong Released Tablets (1 mg and 5 mg)",
    productDevStatus: "Done",
    pilotBE: "Pending",
    scaleUp: "Pending",
    pivotalBE: "Pending",
    technologyTransfer: "Pending",
    remark: "Development Ongoing",
    avaliableIn: [
      {
        key: "USA",
        value: true,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },
  {
    srno: 6,
    productID: "2006",
    rLDProduct_Dev: "Hemedy",
    productName:"Dexamethasone Tablets (20 mg)",
    productDevStatus: "Done",
    pilotBE: "Done",
    scaleUp: "Done",
    pivotalBE: "Done",
    technologyTransfer: "Done",
    remark: "ANDA Filed",
    avaliableIn: [
      {
        key: "USA",
        value: false,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },
  {
    srno: 7,
    productID: "2007",
    rLDProduct_Dev: "Famo PFOS (Lupin)",
    productName: "Famotidine Powder For Oral Suspension 40mg/ml",
    productDevStatus: "Done",
    pilotBE: "Done",
    scaleUp: "Done",
    pivotalBE: "Done",
    technologyTransfer: "Done",
    remark: "ANDA Filed",
    avaliableIn: [
      {
        key: "USA",
        value: false,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },
  {
    srno: 8,
    productID: "2008",
    rLDProduct_Dev: "Fenoglide",
    productName:
      "Fenofibrate Tablets 40 mg and 120 mg",
    productDevStatus: "Done",
    pilotBE: "Done",
    scaleUp: "",
    pivotalBE: "Pending",
    technologyTransfer: "Pending",
    remark: "Development Ongoing",
    avaliableIn: [
      {
        key: "USA",
        value: false,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },
  {
    srno: 9,
    productID: "2009",
    rLDProduct_Dev: "Intelence",
    productName: "Etraverine Tablets 25 mg, 100 mg and 200 mg",
    productDevStatus: "Done",
    pilotBE: "",
    scaleUp: "Pending",
    pivotalBE: "Pending",
    technologyTransfer: "Pending",
    remark: "Pilot Ongoing",
    avaliableIn: [
      {
        key: "USA",
        value: false,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },
  {
    srno: 10,
    productID: "2010",
    rLDProduct_Dev: "Pritin",
    productName:
      "Rifapentine Tablets 200 mg",
    productDevStatus: "Done",
    pilotBE: "Done",
    scaleUp: "",
    pivotalBE: "Pending",
    technologyTransfer: "Pending",
    remark: "Pilot Successfully Completed",
    avaliableIn: [
      {
        key: "USA",
        value: false,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },

  {
    srno: 11,
    productID: "2011",
    rLDProduct_Dev: "Innpopran XL (USA)",
    productName: "Propranolol Hydrochloride ER Capsules (80 mg and120 mg)",
    productDevStatus: "Done",
    pilotBE: "",
    scaleUp: "Pending",
    pivotalBE: "Pending",
    technologyTransfer: "Pending",
    remark: "Development Ongoing",
    avaliableIn: [
      {
        key: "USA",
        value: true,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },


  {
    srno: 12,
    productID: "2012",
    rLDProduct_Dev: "Lyvispah (USA)",
    productName: "Baclofen Oral Granules 5, 10, 20 mg",
    productDevStatus: "Done",
    pilotBE: "",
    scaleUp: "Pending",
    pivotalBE: "Pending",
    technologyTransfer: "Pending",
    remark: "Development Ongoing",
    avaliableIn: [
      {
        key: "USA",
        value: true,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },

  {
    srno: 13,
    productID: "2013",
    rLDProduct_Dev: "Fleqsuvy (USA)",
    productName: "Baclofen Oral suspension 25 mg / 5ml",
    productDevStatus: "Done",
    pilotBE: "",
    scaleUp: "Pending",
    pivotalBE: "Pending",
    technologyTransfer: "Pending",
    remark: "Development Ongoing",
    avaliableIn: [
      {
        key: "USA",
        value: true,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },

  {
    srno: 14,
    productID: "2014",
    rLDProduct_Dev: "Relistor (USA)",
    productName: "MethylNaltrexone Bromide Tablets 150 mg",
    productDevStatus: "Done",
    pilotBE: "",
    scaleUp: "Pending",
    pivotalBE: "Pending",
    technologyTransfer: "Pending",
    remark: "Ready for Pilot",
    avaliableIn: [
      {
        key: "USA",
        value: true,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },

  // {
  //   srno: 15,
  //   productID: "2015",
  //   rLDProduct_Dev: "Xarelto",
  //   productName: "Rivaroxaben Powder for Oral Suspension (1 mg / ml)",
  //   productDevStatus: "",
  //   pilotBE: "",
  //   scaleUp: "Pending",
  //   pivotalBE: "Pending",
  //   technologyTransfer: "Pending",
  //   remark: "Development Ongoing",
  //   avaliableIn: [
  //     {
  //       key: "USA",
  //       value: true,
  //     },
  //     {
  //       key: "EU",
  //       value: true,
  //     },
  //     {
  //       key: "China",
  //       value: true,
  //     },
  //     {
  //       key: "Australia",
  //       value: true,
  //     },
  //     {
  //       key: "S.E. Asia",
  //       value: true,
  //     },
  //     {
  //       key: "Other",
  //       value: true,
  //     },
  //   ],
  // },

  {
    srno: 16,
    productID: "2016",
    rLDProduct_Dev: "Nymalize (USA)",
    productName: "Nimodipine Oral Solution 6 mg / ml",
    productDevStatus: "Done",
    pilotBE: "Done",
    scaleUp: "Pending",
    pivotalBE: "Pending",
    technologyTransfer: "Pending",
    remark: "Ready for Scale Up",
    avaliableIn: [
      {
        key: "USA",
        value: true,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },

  {
    srno: 17,
    productID: "2017",
    rLDProduct_Dev: "OZOBAX(USA)",
    productName: "Baclofen Oral Solution 5mg/ 5ml",
    productDevStatus: "Done",
    pilotBE: "",
    scaleUp: "Pending",
    pivotalBE: "Pending",
    technologyTransfer: "Pending",
    remark: "Development Ongoing",
    avaliableIn: [
      {
        key: "USA",
        value: true,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },

  {
    srno: 18,
    productID: "2018",
    rLDProduct_Dev: "Promacta (USA)",
    productName: "Eltrombopag Olamine Tablets (Eq to Acid)12.5, 25, 50, 75 mg",
    productDevStatus: "Done",
    pilotBE: "",
    scaleUp: "Pending",
    pivotalBE: "Pending",
    technologyTransfer: "Pending",
    remark: "Prototype Ready",
    avaliableIn: [
      {
        key: "USA",
        value: false,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },

  {
    srno: 19,
    productID: "2019",
    rLDProduct_Dev: "Promacta Kit (USA)",
    productName: "Eltrombopag Olamine For Suspension (Eq to Acid) 12.5, 25 mg",
    productDevStatus: "Done",
    pilotBE: "",
    scaleUp: "Pending",
    pivotalBE: "Pending",
    technologyTransfer: "Pending",
    remark: "Prototype Ready",
    avaliableIn: [
      {
        key: "USA",
        value: true,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },
];
