export const DifferntiatedProdDevItems = [
  

  {
    srno: 1,
    productID: "3001",
    rLDProduct_Dev: "Lialda",
    productName: "Mesalamine DR Tablets (1.2 gm)",
    productDevStatus: "Done",
    pilotBE: "Done",
    scaleUp: "Done",
    pivotalBE: "Pending",
    technologyTransfer: "Pending",
    remark: "6 M Stability done. Ready for Scale Up",
    avaliableIn: [
      {
        key: "USA",
        value: false,
      },
      {
        key: "EU",
        value: false,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },
  {
    srno: 2,
    productID: "3002",
    rLDProduct_Dev: "Pentasa Sachets",
    productName: "Mesalamine Extended Release Pellets (90% and 96%)",
    productDevStatus: "Done",
    pilotBE: "Pending",
    scaleUp: "Pending",
    pivotalBE: "Pending",
    technologyTransfer: "Pending",
    remark: "Ready for TT",
    avaliableIn: [
      {
        key: "USA",
        value: true,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: false,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },
  {
    srno: 3,
    productID: "3003",
    rLDProduct_Dev: "Pentasa Capsules",
    productName: "Mesalamine Extended Release Capsules (250 and 500 mg)",
    productDevStatus: "Done",
    pilotBE: "Done",
    scaleUp: "Pending",
    pivotalBE: "Pending",
    technologyTransfer: "Pending",
    remark: "6 M Stability done. Encouraging Pilot BE",
    avaliableIn: [
      {
        key: "USA",
        value: false,
      },
      {
        key: "EU",
        value: false,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },

  {
    srno: 4,
    productID: "3004",
    rLDProduct_Dev: "Procysbi",
    productName:"Cysteamine Bitartarate Delayed Release Capsule (25 mg and 75 mg)",
    productDevStatus: "",
    pilotBE: "Pending",
    scaleUp: "Pending",
    pivotalBE: "Pending",
    technologyTransfer: "Pending",
    remark: "Development Ongoing",
    avaliableIn: [
      {
        key: "USA",
        value: false,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },

  {
    srno: 5,
    productID: "3005",
    rLDProduct_Dev: "Lipofen (USA)",
    productName:"Fenofibrate Capsules 50 and 150 mg",
    productDevStatus: "Done",
    pilotBE: "Pending",
    scaleUp: "Pending",
    pivotalBE: "Pending",
    technologyTransfer: "Pending",
    remark: "Development Ongoing",
    avaliableIn: [
      {
        key: "USA",
        value: true,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },
  
  {
    srno: 6,
    productID: "3006",
    rLDProduct_Dev: "Xeljanz XR (USA)",
    productName:"Tofacitinib Extended Release Tablets (11 and 22 mg)",
    productDevStatus: "Done",
    pilotBE: "",
    scaleUp: "Pending",
    pivotalBE: "Pending",
    technologyTransfer: "Pending",
    remark: "Ready for Pilot BE",
    avaliableIn: [
      {
        key: "USA",
        value: true,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },

  {
    srno: 7,
    productID: "3007",
    rLDProduct_Dev: "Auvelity",
    productName:"Buproprion Extended Release and Dexomethorphen HBR Tablets (105 / 45 mg)",
    productDevStatus: "Done",
    pilotBE: "Done",
    scaleUp: "",
    pivotalBE: "Pending",
    technologyTransfer: "Pending",
    remark: "Successful Pilot BE",
    avaliableIn: [
      {
        key: "USA",
        value: true,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },

  {
    srno: 8,
    productID: "3008",
    rLDProduct_Dev: "Zuplenz (USA)",
    productName:"Ondenseteron Oral Dissolving Film 4 and 8 mg",
    productDevStatus: "Done",
    pilotBE: "",
    scaleUp: "Pending",
    pivotalBE: "Pending",
    technologyTransfer: "Pending",
    remark: "Development Ongoing",
    avaliableIn: [
      {
        key: "USA",
        value: true,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },

  {
    srno: 9,
    productID: "3009",
    rLDProduct_Dev: "Concerta (USA/EU)",
    productName:"Methylphenedate Extended Release Tablets 18,27, 36, 54 and 72 mg",
    productDevStatus: "Done",
    pilotBE: "Done",
    scaleUp: "Done",
    pivotalBE: "Pending",
    technologyTransfer: "Pending",
    remark: "Successful Pilot BE Using Matrix Formulation",
    avaliableIn: [
      {
        key: "USA",
        value: false,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },

  {
    srno: 10,
    productID: "3010",
    rLDProduct_Dev: "Triglide (USA)",
    productName:"Fenofibrate Tablets (160 mg)",
    productDevStatus: "Done",
    pilotBE: "Done",
    scaleUp: "",
    pivotalBE: "",
    technologyTransfer: "Pending",
    remark: "Development Ongoing",
    avaliableIn: [
      {
        key: "USA",
        value: true,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },

  {
    srno: 11,
    productID: "3011",
    rLDProduct_Dev: "Lupin (RS)",
    productName:"Orphenedrine Citrate Extended Release Tablets 100 mg",
    productDevStatus: "Done",
    pilotBE: "Done",
    scaleUp: "Done",
    pivotalBE: "Pending",
    technologyTransfer: "Pending",
    remark: "Ready For TT",
    avaliableIn: [
      {
        key: "USA",
        value: false,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },

  {
    srno: 12,
    productID: "3012",
    rLDProduct_Dev: "Intuniv XR",
    productName:"Guanfacine HCL ER Tablet 1, 2, 3, 4 mg",
    productDevStatus: "Done",
    pilotBE: "Done",
    scaleUp: "Done",
    pivotalBE: "Pending",
    technologyTransfer: "Pending",
    remark: "Ready For TT",
    avaliableIn: [
      {
        key: "USA",
        value: false,
      },
      {
        key: "EU",
        value: true,
      },
      {
        key: "China",
        value: true,
      },
      {
        key: "Australia",
        value: true,
      },
      {
        key: "S.E. Asia",
        value: true,
      },
      {
        key: "Other",
        value: true,
      },
    ],
  },
];
