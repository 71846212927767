import React from "react";
import { Link } from "react-router-dom";
// import { Link as Rlink } from "react-scroll";

// import Certificate from "../../images/certificate.jpg";
import Quality from "../../images/medical.png";
import Reserch from "../../images/pharmaceutical (2).png";
import AboutIcon from "../../images/about.png";

import "../../App.css";
import "./Home.css";

export default function Home() {
  return (
    <div className="FullSize home Dash">
      <div className="Main heading">
        <div className="mainTitle">
          {/* <h3 className="Awards_Highlight">
            <div className="Head">BREAKING NEWS</div>
            <div className="Body">
              <span>
                Comming Soon...&nbsp;
                <span className="Go_To_moreAboutAwards" href="">
                  <Rlink to="about" spy={true} smooth={true}>
                    Know more
                  </Rlink>
                </span>
              </span>
            </div>
          </h3> */}
          <div className="Primery Heading">
            <p className="Line1">
              DEVELOPMENT PARTNER FOR<br></br>
              <span className="Special_wordDiff">GENERIC</span> AND
              <span className="Special_wordInno"> COMPLEX GENERIC </span>
              HEALTHCARE SOLUTIONS
            </p>
          </div>
        </div>
      </div>
      <div className="Discription">
        <p><b>
        ‘Product Development Partner’ for the ‘Generics and Complex Generics Drug Delivery Systems’</b><br></br> 
          {/* Aims to achieving the mission&nbsp;
          <span className="newFontTagline">
            “Reducing the Burden of Healthcare”.
          </span> */}
          {/* Working with us will help to meet the Project Time lines and Cost for our Partner.  */}
        </p>
      </div>

      <div className="Border_Bottom"></div>
      {/* <div className="WhatWeDo">
        <h4 className="AboutUs_WhatWeDo">WHAT WE DO</h4>
        <ul className="iconList">
          <li>
            <img src={Innovate} alt="Innovate"></img>
            INNOVATE
            <p>
              We Do Incremental Innovation To Develop Differentiated Formulation
              Which Impact Peoples Lives.
            </p>
          </li>

          <li>
            <img src={Develop} alt="Develop"></img>
            DEVELOP
            <p>
              We Develop Technologies and Process for Pharmaceutical and
              Nutraceutical Formulations.
            </p>
          </li>

          <li>
            <img src={Deliver} alt="Deliver"></img>
            DELIVER
            <p>
              We Deliver Innovative And Differentiated Formulations with Client
              Satisfaction.
            </p>
          </li>
        </ul>
      </div> */}
      <div className="PagesCards">
        <div className="PageCard1">
          <h1 className="PageCardTitle">
            <p>About Organization</p>
          </h1>
          <img className="PageCardTitleIcon" src={AboutIcon} alt="About"></img>
          <p className="PageDiscriptionShort">
          DelNova is a fully integrated healthcare company with capabilities to develop and manufacture small and large molecules. We can offer simple and reproducible manufacturing processes, affordable healthcare solutions, and clinically equivalent Generic and Complex Generic pharmaceutical products.
          </p>
          <p className="PageLink">
            <Link className="hover-underline-animation" to="/AboutUs">
              Learn more
            </Link>
          </p>
        </div>

        <div className="PageCard2">
          <h1 className="PageCardTitle">
            <p> Research And Development</p>
          </h1>
          <img className="PageCardTitleIcon" src={Reserch} alt="About"></img>
          <p className="PageDiscriptionShort">
          Our dedicated and experienced team will support our Partners to work on incremental innovation for product and process development of drug delivery systems. 
        <br></br>
        With support from IP team, our Formulation and Analytical team coordinate to work together for timely delivery of the Generics and Complex Generics products. We help our Partner on redefining the product portfolio with cost effective and timely services.
          </p>
          <p className="PageLink">
            <Link className="hover-underline-animation" to="/ResearchAndDevlop">
              Learn more
            </Link>
          </p>
        </div>
        <div className="PageCard3">
          <h1 className="PageCardTitle">
            <p>
              {" "}
              Quality <span className="Special_In">iN</span> Design
            </p>
          </h1>
          <img className="PageCardTitleIcon" src={Quality} alt="About"></img>
          <p className="PageDiscriptionShort">
            We build <span className="Special_In">iN</span> quality. With our highly experienced and dedicated technical team, we laid the foundation of the product development. Our selection of process, composition and approach will build in quality formulation.
            <br></br>
            This will help our partner to have stable formulation and scalable process.
            <br></br>
            While to our patients, we help to get cost effective healthcare solutions.

          </p>
          <p className="PageLink">
            <Link className="hover-underline-animation" to="/Pipeline">
              Learn more
            </Link>
          </p>
        </div>
      </div>
      <div className="Border_Bottom"></div>
      <div className="More_About_Award" id="about">
        <div className="Award_Image">
          {/* <img src={Certificate} alt=""></img> */}
        </div>
        <div className="Award_Description">
          <h2 className="Award_Description_Head">
            {/* The Award for the “Best Product Development Partner” */}
            {/* Stay Tuned For More Details... */}
          </h2>
          {/* <span className="Award_Description_Body">
            “We have been awarded in China for the ‘Product Development Partner’
            for the ‘Generics and Complex Generics Drug Delivery Systems’”
          </span>
          <span className="Award_Description_Body">
            “We have been awarded in China for the ‘Product Development Partner’
            for the ‘Generics and Complex Generics Drug Delivery Systems’”
          </span>
          <span className="Award_Description_Body">
            “We have been awarded in China for the ‘Product Development Partner’
            for the ‘Generics and Complex Generics Drug Delivery Systems’”
          </span> */}
        </div>
      </div>
    </div>
  );
}
